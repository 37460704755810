import { default as _91id_93CtOIsdqfENMeta } from "/home/forge/merchants.strauss-sta.smake.eu/releases/20240917153124/pages/[slug]/catalog/logos/[id].vue?macro=true";
import { default as indexJTKHwZgffjMeta } from "/home/forge/merchants.strauss-sta.smake.eu/releases/20240917153124/pages/[slug]/catalog/logos/index.vue?macro=true";
import { default as _91id_93ijdq9tvN2tMeta } from "/home/forge/merchants.strauss-sta.smake.eu/releases/20240917153124/pages/[slug]/customers/[customer]/logos/[id].vue?macro=true";
import { default as indexrMs7Zc8qGjMeta } from "/home/forge/merchants.strauss-sta.smake.eu/releases/20240917153124/pages/[slug]/customers/[customer]/logos/index.vue?macro=true";
import { default as colorsXNwMz9tDzUMeta } from "/home/forge/merchants.strauss-sta.smake.eu/releases/20240917153124/pages/[slug]/customers/[id]/colors.vue?macro=true";
import { default as favorite_45variantswI6y8ksdGiMeta } from "/home/forge/merchants.strauss-sta.smake.eu/releases/20240917153124/pages/[slug]/customers/[id]/favorite-variants.vue?macro=true";
import { default as indexHwv3Eu4P8kMeta } from "/home/forge/merchants.strauss-sta.smake.eu/releases/20240917153124/pages/[slug]/customers/[id]/index.vue?macro=true";
import { default as indexGF6aK7AX5oMeta } from "/home/forge/merchants.strauss-sta.smake.eu/releases/20240917153124/pages/[slug]/customers/index.vue?macro=true";
import { default as dashboardaYfaecVBF8Meta } from "/home/forge/merchants.strauss-sta.smake.eu/releases/20240917153124/pages/[slug]/dashboard.vue?macro=true";
import { default as entrance5bUZCiqaFUMeta } from "/home/forge/merchants.strauss-sta.smake.eu/releases/20240917153124/pages/[slug]/entrance.vue?macro=true";
import { default as indexnW5qrdgbudMeta } from "/home/forge/merchants.strauss-sta.smake.eu/releases/20240917153124/pages/[slug]/index.vue?macro=true";
import { default as loginbC8vXKjE4XMeta } from "/home/forge/merchants.strauss-sta.smake.eu/releases/20240917153124/pages/[slug]/login.vue?macro=true";
import { default as indexDSRzuxTsslMeta } from "/home/forge/merchants.strauss-sta.smake.eu/releases/20240917153124/pages/index.vue?macro=true";
export default [
  {
    name: "slug-catalog-logos-id",
    path: "/:slug()/catalog/logos/:id()",
    meta: _91id_93CtOIsdqfENMeta || {},
    component: () => import("/home/forge/merchants.strauss-sta.smake.eu/releases/20240917153124/pages/[slug]/catalog/logos/[id].vue").then(m => m.default || m)
  },
  {
    name: indexJTKHwZgffjMeta?.name ?? "slug-catalog-logos",
    path: "/:slug()/catalog/logos",
    meta: indexJTKHwZgffjMeta || {},
    component: () => import("/home/forge/merchants.strauss-sta.smake.eu/releases/20240917153124/pages/[slug]/catalog/logos/index.vue").then(m => m.default || m)
  },
  {
    name: "slug-customers-customer-logos-id",
    path: "/:slug()/customers/:customer()/logos/:id()",
    meta: _91id_93ijdq9tvN2tMeta || {},
    component: () => import("/home/forge/merchants.strauss-sta.smake.eu/releases/20240917153124/pages/[slug]/customers/[customer]/logos/[id].vue").then(m => m.default || m)
  },
  {
    name: "slug-customers-customer-logos",
    path: "/:slug()/customers/:customer()/logos",
    meta: indexrMs7Zc8qGjMeta || {},
    component: () => import("/home/forge/merchants.strauss-sta.smake.eu/releases/20240917153124/pages/[slug]/customers/[customer]/logos/index.vue").then(m => m.default || m)
  },
  {
    name: "slug-customers-id-colors",
    path: "/:slug()/customers/:id()/colors",
    meta: colorsXNwMz9tDzUMeta || {},
    component: () => import("/home/forge/merchants.strauss-sta.smake.eu/releases/20240917153124/pages/[slug]/customers/[id]/colors.vue").then(m => m.default || m)
  },
  {
    name: "slug-customers-id-favorite-variants",
    path: "/:slug()/customers/:id()/favorite-variants",
    meta: favorite_45variantswI6y8ksdGiMeta || {},
    component: () => import("/home/forge/merchants.strauss-sta.smake.eu/releases/20240917153124/pages/[slug]/customers/[id]/favorite-variants.vue").then(m => m.default || m)
  },
  {
    name: "slug-customers-id",
    path: "/:slug()/customers/:id()",
    meta: indexHwv3Eu4P8kMeta || {},
    component: () => import("/home/forge/merchants.strauss-sta.smake.eu/releases/20240917153124/pages/[slug]/customers/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: indexGF6aK7AX5oMeta?.name ?? "slug-customers",
    path: "/:slug()/customers",
    meta: indexGF6aK7AX5oMeta || {},
    component: () => import("/home/forge/merchants.strauss-sta.smake.eu/releases/20240917153124/pages/[slug]/customers/index.vue").then(m => m.default || m)
  },
  {
    name: dashboardaYfaecVBF8Meta?.name ?? "slug-dashboard",
    path: "/:slug()/dashboard",
    meta: dashboardaYfaecVBF8Meta || {},
    component: () => import("/home/forge/merchants.strauss-sta.smake.eu/releases/20240917153124/pages/[slug]/dashboard.vue").then(m => m.default || m)
  },
  {
    name: "slug-entrance",
    path: "/:slug()/entrance",
    meta: entrance5bUZCiqaFUMeta || {},
    component: () => import("/home/forge/merchants.strauss-sta.smake.eu/releases/20240917153124/pages/[slug]/entrance.vue").then(m => m.default || m)
  },
  {
    name: "slug",
    path: "/:slug()",
    component: () => import("/home/forge/merchants.strauss-sta.smake.eu/releases/20240917153124/pages/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: loginbC8vXKjE4XMeta?.name ?? "slug-login",
    path: "/:slug()/login",
    meta: loginbC8vXKjE4XMeta || {},
    component: () => import("/home/forge/merchants.strauss-sta.smake.eu/releases/20240917153124/pages/[slug]/login.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    component: () => import("/home/forge/merchants.strauss-sta.smake.eu/releases/20240917153124/pages/index.vue").then(m => m.default || m)
  }
]